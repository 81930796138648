.myContainer{
    padding: 0;
}

.pergunta{
    background: #9a9ad1;
    color: white;
    border-radius: 20px;
}

.resposta{
    background: #7373a8;
    color: white;
    border-radius: 10px;
}

.resposta:hover{
    background: #9a9ad1;
}